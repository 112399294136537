.spotifyWrapper {
    margin: 0 0 20px 0;
}

p {
    text-align: left;
}

.textLink, .textLink:hover {
    color: white;
}