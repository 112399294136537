.icon {
    margin-right: 0.5rem;
}

.link {
    color:inherit;
    text-decoration: none;
}

.link:hover {
     color: lightgray;
}

.center {
    text-align: center;
    margin: 1rem 0 1rem 0;
}

@media (min-width: 767px) {
    .textWrapper {
        margin: 0 5% 0 5%
    }
}

.centeredParagraph {
    text-align: center;
    margin: 0;
}