.textWrapper p {
    text-align: left;
    hyphens: auto;
}

@media (max-width: 767px) {
    .singerWrapper {
        padding: 0;
    }

    .imageWrapper {
        margin-bottom: 30px;
    }
}

@media (min-width: 767px) {
    .textWrapper {
        margin: 0 5% 50px 5%
    }
}