.imageWrapper {
    padding: 0 60px 60px 60px;
    
}

.projectWrapper {
    margin-top: 60px;
}

.projectHeader {
    text-align: left;
}

.smallCol {
    padding: 0!important;
}

.smallColsContainer {
    width: 60%;
}

.textLink, .textLink:hover {
    color: white;
}