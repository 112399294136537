.icon {
    margin-right: 0.5rem;
}

.columnHeader {
    font-weight: 900;
    margin-bottom: 0.5rem;
    font-size: 1.3rem;
    text-align: left;
}

.copyright {
    margin-top: 3rem;
    text-align: center;
}

.link {
    color:inherit;
    text-decoration: none;
}

.link:hover {
     color: lightgray;
}

@media (max-width: 767px) {
    .columnHeader {
        margin-top: 30px;
    }
}