.imageWrapper {
  aspect-ratio: 1.526;
  overflow: hidden;
}

.wrapper {
  margin-bottom: 2em;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
}

.textWrapper {
  background-color: white;
  color: black;
  padding: 1.5rem 0 1rem 0;
  min-height: 150px;
}

.concertTitle {
  font-size: 19px;
  margin: auto 0 5px 15px;
  font-weight: bold;
}

.details {
  margin: 0 0 0 15px;
  padding: 0;
}