.wrapper {
    position: relative;
    height: 100vh;
    background-size: cover !important;
    background-position: center center !important;
}

.imageTitle {
    color: white;
    margin: 0 auto 0 auto;
}

.overlay {
    background: rgba(35, 33, 33, 0.33);
    /* background: rgba(25, 50, 100, 0.33); */
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
    position: absolute;
    padding-top: 20%;
}

@media (max-width: 767px) {
    .overlay {
        padding-top: 80%;
    }

    .imageTitle {
        width: 85%;
        color: white;
    }
}


    /* Stolen from https://unused-css.com/blog/animated-down-arrow/ */
    .scrollDown {
        position: absolute;
        left: 50%;
        top: 12em;
        display: block;
        text-align: center;
        font-size: 20px;
        z-index: 100;
        text-decoration: none;
        text-shadow: 0;
        width: 50px;
        height: 50px;
        border-bottom: 5px solid #fff;
        border-right: 5px solid #fff;
        z-index: 9;
        left: 50%;
        -webkit-transform: translate(-50%, 0%) rotate(45deg);
        -moz-transform: translate(-50%, 0%) rotate(45deg);
        transform: translate(-50%, 0%) rotate(45deg);
        -webkit-animation: fade_move_down 4s ease-in-out infinite;
        -moz-animation: fade_move_down 4s ease-in-out infinite;
        animation: fade_move_down 4s ease-in-out infinite;
    }


    /*animated scroll arrow animation*/
    @-webkit-keyframes fade_move_down {
        0% {
            -webkit-transform: translate(0, -10px) rotate(45deg);
            opacity: 0;
        }

        50% {
            opacity: 1;
        }

        100% {
            -webkit-transform: translate(0, 10px) rotate(45deg);
            opacity: 0;
        }
    }

    @-moz-keyframes fade_move_down {
        0% {
            -moz-transform: translate(0, -10px) rotate(45deg);
            opacity: 0;
        }

        50% {
            opacity: 1;
        }

        100% {
            -moz-transform: translate(0, 10px) rotate(45deg);
            opacity: 0;
        }
    }

    @keyframes fade_move_down {
        0% {
            transform: translate(0, -10px) rotate(45deg);
            opacity: 0;
        }

        50% {
            opacity: 1;
        }

        100% {
            transform: translate(0, 10px) rotate(45deg);
            opacity: 0;
        }
    }