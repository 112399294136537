@import url('https://fonts.googleapis.com/css2?family=Unica+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;700&display=swap');

h1 {
    font-family: 'Unica One', cursive;
}

h3 {
  font-family: 'Roboto Condensed', sans-serif;
}

p {
  font-family: 'Roboto Condensed', sans-serif;
}

.animation {
  animation-duration: 2s;
  animation-fill-mode: both;
}

@keyframes fadeInDown {
  0% {
     opacity: 0;
     transform: translateY(-20px);
  }
  100% {
     opacity: 1;
     transform: translateY(0);
  }
}

.fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeInRight {
  0% {
     opacity: 0;
     transform: translateX(-20px);
  }
  100% {
     opacity: 1;
     transform: translateY(0);
  }
}

.fadeInRight {
  animation-name: fadeInRight;
}

@keyframes fadeInLeft {
  0% {
     opacity: 0;
     transform: translateX(20px);
  }
  100% {
     opacity: 1;
     transform: translateY(0);
  }
}

.fadeInRight {
  animation-name: fadeInLeft;
}


/* Old styles - can probably be deleted */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
