.wrapper {
    margin-bottom: 2em;
    display: inline-block;
  }

  .textWrapper {
    background-color: white;
    color: black;
    padding: 1rem 0 1.5rem 0;
  }

  .imageWrapper {
    aspect-ratio: 0.8;
    overflow: hidden;
  }

  .singerName {
    margin: auto 0 0 15px;
    font-size: 19px;
    color: black!important;
    font-weight: bold;
  }

  .singerPart {
    margin: 0 0 0 15px;
    color: black !important;
    font-weight: normal;

  }

 
